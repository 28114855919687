/**
 * FooterCollapseItem
 * 
 * @Author: Focci
 * @Date: 2023-10-17 13:53:43
 * @Last Modified by: Focci
 * @Last Modified time: 2023-10-17 13:53:43
 */

'use client'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useCallback, useEffect, useState } from 'react'
import useApp from '@hook/useApp'

export default function FooterCollapseItem({
  title = '',
  children
}) {
  const { isMobile } = useApp()
  const [collapse, setCollapse] = useState(false)

  useEffect(() => { setCollapse(isMobile) }, [isMobile])

  const handleClick = useCallback(() => {
    isMobile && setCollapse((v) => !v)
  }, [isMobile])

  return (
    <div>
      <div 
        className="flex items-center justify-between"
        tabIndex="0"
        role="button"
        onKeyPress={handleClick}
        onClick={handleClick}
      >
        <b className="text-f.8 md:text-f.7 cursor-default">{title}</b>
        {isMobile && <ExpandMoreIcon className="block md:hidden" />}
      </div>
      <div className={clsx(['mt-1', collapse && 'hidden'])}>
        {children}
      </div>
    </div>
  )
}

FooterCollapseItem.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
}
